import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import {
  Accordion,
  AccordionBody,
  AccordionItem,
  AccordionTitle,
  CloseIcon,
  IconWrapper,
  OpenIcon
} from 'common/src/components/Accordion';
import Container from 'common/src/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo/plus';
import { minus } from 'react-icons-kit/entypo/minus';

import FaqWrapper from './faq.style';

import nl2br from 'react-newline-to-break';
import Button from 'common/src/components/Button';

const FaqSection = ({
                      sectionWrapper,
                      row,
                      col,
                      secTitleWrapper,
                      secHeading,
                      secText,
                      title,
                      description,
                      buttonWrapper,
                      button,
                      faqData,
                      hideExtraMessage
                    }) => {
  const Data = useStaticQuery(graphql`
      query {
          saasModernJson {
              FAQ_DATA {
                  title
                  description
                  expend
              }
          }
      }
  `);
  if (!faqData)
    faqData = Data.saasModernJson.FAQ_DATA;

  return (
    <Box {...sectionWrapper} id="faq_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="FREQUENTLY ASKED QUESTIONS"/>
          <Heading {...secHeading} content="What would you like to know? 🕵️‍"/>
        </Box>
        <Box {...row}>
          <Box {...col}>
            <FaqWrapper>
              <Accordion>
                <>
                  {faqData.map((accordionItem, index) => (
                    <AccordionItem
                      id={`faq${index}`}
                      className="accordion_item"
                      key={`accordion-${index}`}
                      expanded={accordionItem.expend}
                    >
                      <>
                        <AccordionTitle className="accordion_title">
                          <>
                            <Heading {...title} content={accordionItem.title}/>
                            <IconWrapper>
                              <OpenIcon>
                                <Icon icon={minus} size={18}/>
                              </OpenIcon>
                              <CloseIcon>
                                <Icon icon={plus} size={18}/>
                              </CloseIcon>
                            </IconWrapper>
                          </>
                        </AccordionTitle>
                        <AccordionBody className="accordion_body">
                          <Text
                            {...description}
                            content={nl2br(accordionItem.description)}
                          />
                        </AccordionBody>
                      </>
                    </AccordionItem>
                  ))}
                </>
              </Accordion>
            </FaqWrapper>
            {!hideExtraMessage && <Box {...buttonWrapper} style={{ textAlign: 'center' }}>
              Are you looking for custom leads or something else? Tell us what you want and we'll
              get back to you within 24 hrs.
            </Box>}
            <Box {...buttonWrapper} mt="20px">
              <a href="mailto:hello@b2bsaasleads.com">
                <Button {...button} title="Contact us"/>
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

FaqSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object
};

FaqSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['20px', '30px', '50px', '50px'],
    pb: ['20px', '30px', '30px', '30px']
  },
  secTitleWrapper: {
    mb: ['55px', '65px']
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px'
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67'
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  col: {
    width: ['100%', '100%', '75%', '75%']
  },
  title: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: 0
  },
  description: {
    fontSize: '15px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: 0
  },
  buttonWrapper: {
    mt: '50px',
    flexBox: true,
    justifyContent: 'center'
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'primary',
    minWidth: '150px'
  }
};

export default FaqSection;
